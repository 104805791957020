.textArea {
    display: list-item;
    margin-left: 1em;
    outline: none;
    height: 100%;
    font-size: 14px;
  }
  
  .textArea div {
    display: list-item;
  }